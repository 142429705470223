export const environment = {
  production: false,
  apiBaseUrl: 'https://showcase.dev.vibeiq.com/api-dev',
  showcaseAppHost: 'https://staging.showcase-manager.contrailplatform.com/',
  imageHost: 'showcase.dev.vibeiq.com',
  userPoolRegion: 'us-east-1',
  userPoolId: 'us-east-1_e2cA0nJWu',
  userPoolWebClientId: 'nnio2uhtq7qfk1tjmi7tgg63p',
  name: 'FEATURE_BRANCH',
  googleAnalyticsKey: 'UA-179147959-2',
  intercomAppId: 'e2uyp9md',
  domain: '.contrailplatform.com',
  loginUrl: 'https://login.contrailplatform.com/',
  appName: '',
  websocketService: 'wss://qde1jn70k0.execute-api.us-east-1.amazonaws.com/dev/',
};
